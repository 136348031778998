<template>

  <div class="container page-container">

    <div class="p-4">

      <div class="has-text-centered mt-6 mb-6">

        <h1 class="has-text-grey-dark
                   has-text-weight-normal
                   is-size-4
                   is-size-5-mobile
                   mb-4">
          Bienvenue dans votre espace personnel !
        </h1>

      </div>

      <div class="is-flex is-justify-content-flex-end is-align-items-center mb-4">
        <b-button
          label="Ajouter un établissement"
          type="is-secondary"
          icon-left="plus-circle-outline"
          rounded
          id="v-step-0"
          class="mr-3"
          @click="isCreateRestorantModaleActive = true"
          :disabled="restaurants.length >= 5"
        />
        <b-button
          label="Lancer tutoriel"
          type="is-light"
          icon-left="school"
          rounded
          @click="playTour"
        />
      </div>

      <div id="v-step-1" class="mb-6 card-grid">

        <b-loading :is-full-page="false" v-model="isLoadingRestaurants" />

        <div
          class="card is-clickable"
          v-for="restaurant in restaurants"
          :key="restaurant._id"
          @click="() => openRestaurant(restaurant)"
        >

          <div class="card-content">

            <div class="media">
              <div v-if="restaurant.logo && restaurant.logo.url" class="media-left">
                <figure class="image is-64x64 ml-0 mr-2">
                  <img class="is-rounded" :src="restaurant.logo.url">
                </figure>
              </div>
              <div class="media-content">
                <div
                  class="is-flex
                         is-align-items-center
                         is-justify-content-space-between"
                  style="min-height: 64px;"
                >
                  <div>
                    <p class="title
                              is-6
                              has-text-weight-semibold
                              has-text-grey-dark">
                      {{ restaurant.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="content">
              <div class="card-description has-text-justified">
                {{ truncateString(restaurant.description) }}
              </div>
              <div class="mt-4">
                <small class="has-text-grey">
                  Mis à jour <b>{{ formatedDate(restaurant.updatedAt) }}</b>
                </small>
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>

    <v-tour name="myTour" :steps="steps" :options="{ highlight: true }">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            class="p-4 box"
          >

            <div slot="header">
              <p
                class="has-text-weight-semibold has-text-centered has-text-grey-darker"
                v-html="tour.steps[tour.currentStep].header"
              />
            </div>

            <div slot="content">
              <p
                class="is-size-7 has-text-justified has-text-grey my-5"
                style="width: 250px;"
                v-html="tour.steps[tour.currentStep].content"
              />
            </div>

            <div slot="actions">
              <div class="my-3 is-flex is-justify-content-center">
                <div
                  v-for="(s, sindex) in tour.steps"
                  :key="s.currentStep"
                  class="tour_dot"
                  :class="{ 'tour_dot_current_step': sindex === tour.currentStep }"
                />
              </div>
              <div class="is-flex is-justify-content-space-between">
                <b-button
                  label="Commencer"
                  type="is-secondary"
                  rounded
                  expanded
                  @click="tour.stop"
                  v-if="tour.isLast"
                  size="is-small"
                />
                <b-button
                  label="Passer"
                  type="is-ghost"
                  rounded
                  size="is-small"
                  @click="tour.skip"
                  v-if="!tour.isLast"
                />
                <b-button
                  label="Suivant"
                  type="is-secondary"
                  outlined
                  rounded
                  size="is-small"
                  @click="tour.nextStep"
                  v-if="!tour.isLast"
                />
              </div>
            </div>

          </v-step>
        </transition>
      </template>
    </v-tour>

    <b-modal
      v-model="isCreateRestorantModaleActive"
      has-modal-card
      trap-focus
      :can-cancel="['x']"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="Create restaurant dialog"
      aria-modal
      :full-screen="isMobile"
    >
      <template #default="props">
        <app-create-restaurant-form
          @close="props.close"
          @add-restaurant="onAddRestaurant"
          @refresh="fetchRestaurants()"
        />
      </template>
    </b-modal>

  </div>

</template>

<script>
import CreateRestaurantForm from '@/components/back_office/CreateRestaurantForm.vue';
import moment from 'moment';
import truncate from 'lodash.truncate';

export default {
  components: {
    'app-create-restaurant-form': CreateRestaurantForm,
  },
  data: () => ({
    restaurants: [],
    isLoadingRestaurants: false,
    isCreateRestorantModaleActive: false,
    windowInnerWidth: window.innerWidth,
    steps: [
      {
        target: '#v-step-0',
        header: 'Ajouter un établissement',
        content: 'Pour commencer ajouter un établissement (bar, restaurant...etc)',
        params: {
          enableScrolling: false,
          placement: 'left',
        },
      },
      {
        target: '#v-step-1',
        header: 'Modifier un établissement',
        content: 'Une fois créé, vous pouvez éditer votre établissement et son menu en cliquant sur la vignette correspondante',
      },
    ],
  }),
  created() {
    this.fetchRestaurants();
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    // this.playTour();
  },
  computed: {
    isMobile() {
      return this.windowInnerWidth < 768;
    },
  },
  methods: {
    playTour() {
      // eslint-disable-next-line dot-notation
      this.$tours['myTour'].start();
    },
    onResize() {
      this.windowInnerWidth = window.innerWidth;
    },
    truncateString(str, n = 100) {
      return truncate(str, {
        length: n,
        separator: /,? +/,
      });
    },
    async fetchRestaurants() {
      this.isLoadingRestaurants = true;
      try {
        const res = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/restaurants`,
        );

        this.restaurants = res.data;
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      } finally { this.isLoadingRestaurants = false; }
    },
    onAddRestaurant(restaurant) {
      this.restaurants.push(restaurant);
    },
    formatedDate(nativeDate) {
      const lastUpdate = moment(nativeDate);

      return lastUpdate.fromNow();
    },
    openRestaurant(restaurant) {
      const restaurantId = restaurant._id;

      this.$router.push({
        name: 'BO_RestaurantGeneral',
        params: {
          restaurantId,
        },
      });
    },
    goToClientView(restaurantId) {
      const routeData = this.$router.resolve({
        name: 'Restaurant',
        params: { restaurantId },
      });

      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
  .page-container {
    min-height: calc(100vh - 162px);
  }

  .confetti-icon {
    font-size: 1.2em;
  }

  .card-description {
    height: 70px;
  }

  .card-grid {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .tour_dot {
    width: 11px;
    height: 11px;
    border: 1px solid lightgray;
    border-radius: 50%;
    margin: 2px;
  }

  .tour_dot_current_step {
    border: none;
    width: 16px;
    height: 12px;
    border-radius: 6px;
    background-color: #62d4b3;
  }

  @media(max-width: $tablet) {
    .card-description {
      height: auto;
    }
    .card-grid {
      grid-gap: 10px;
      grid-template-columns: 1fr;
    }
  }
</style>