<template>

  <div class="modal-card">

    <header class="modal-card-head">
      <p class="modal-card-title m-0">Nouvel établissement</p>
      <button type="button"
              class="delete"
              @click="$emit('close')"/>
    </header>

    <section class="modal-card-body">

      <b-field
        label="Nom *"
        custom-class="is-small has-text-weight-light has-text-grey"
      >
        <b-input
          v-model.trim="form.name"
          placeholder="Le roi du burger"
          maxlength="200"
          ref="nameInput"
          rounded
        />
      </b-field>

      <b-field
        label="Description"
        custom-class="is-small has-text-weight-light has-text-grey"
      >
        <b-input
          v-model.trim="form.description"
          placeholder="Chaîne de restauration rapide d'origine américaine"
          maxlength="450"
          type="textarea"
        />
      </b-field>

      <b-field
        label="Adresse *"
        custom-class="is-small has-text-weight-light has-text-grey"
        class="mb-4"
      >
        <b-autocomplete
          :data="results"
          placeholder="10 rue saint honoré"
          field="properties.name"
          rounded
          :loading="loading"
          @typing="getAsyncData"
          @select="option => onSelect(option)"
        >

          <template slot-scope="props">
            <div class="media">
              <div class="media-left">
                <b-icon icon="map-marker" />
              </div>
              <div class="media-content">
                {{ props.option.properties.label }}
              </div>
            </div>
          </template>
        </b-autocomplete>
      </b-field>

      <b-field
        label="Code postal *"
        custom-class="is-small has-text-weight-light has-text-grey"
        class="mb-4"
      >
        <b-input
          v-model.trim="form.contactInfos.address.postCode"
          placeholder="75018"
          rounded
        />
      </b-field>

      <b-field
        label="Ville *"
        custom-class="is-small has-text-weight-light has-text-grey"
        class="mb-4"
      >
        <b-input
          v-model.trim="form.contactInfos.address.city"
          rounded
          placeholder="Paris"
        />
      </b-field>

      <app-phone-number-input @isvalid="isTelValid = $event"
                              required
                              @update-phone-number="form.contactInfos.phoneNumber = $event" />

      <p class="is-size-7 has-text-right has-text-grey mt-5">* Champs requis</p>

    </section>

    <footer class="modal-card-foot is-flex is-justify-content-flex-end">
      <b-button label="Annuler" rounded @click="$emit('close')" />
      <b-button
        label="Créer"
        type="is-primary"
        rounded
        @click="create"
        :disabled="!isFormValid"
        icon-left="content-save"
      />
    </footer>

  </div>

</template>

<script>
import PhoneNumberInput from '@/components/PhoneNumberInput.vue';
import debounce from 'lodash.debounce';

export default {
  components: {
    'app-phone-number-input': PhoneNumberInput,
  },
  props: {
    restaurant: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        contactInfos: {
          phoneNumber: {
            country: '',
            countryCallingCode: '',
            nationalNumber: '',
            number: '',
          },
          address: {
            label: '',
            city: '',
            postCode: '',
            cityCode: '',
            houseNumber: '',
            street: '',
            location: {
              type: 'Point',
              coordinates: [],
            },
          },
        },
      },
      results: [],
      selected: null,
      isTelValid: false,
      loading: false,
      error: null,
    };
  },
  mounted() {
    this.$refs.nameInput.focus();
  },
  computed: {
    isValidPhoneNumber() {
      return this.isTelValid;
    },
    isFormValid() {
      return this.form?.name?.trim()
             && this.form?.contactInfos?.address?.city?.trim()
             && this.form?.contactInfos?.address?.postCode?.trim()
             && this.form?.contactInfos?.address?.street?.trim()
             && this.isValidPhoneNumber;
    },
  },
  methods: {
    async create() {
      const newRestaurant = { ...this.form };

      if (!this.form.contactInfos.address.label) {
        const h = newRestaurant.contactInfos.address.houseNumber || '';
        const s = newRestaurant.contactInfos.address.street || '';
        const p = newRestaurant.contactInfos.address.postCode || '';
        const c = newRestaurant.contactInfos.address.city || '';
        // eslint-disable-next-line prefer-template
        newRestaurant.contactInfos.address.label = `${h} ${s} ${p} ${c}`.trim();
      }

      try {
        const { data } = await this.$http.post(
          `${process.env.VUE_APP_API_URL}/restaurants`,
          newRestaurant,
        );

        this.$buefy.toast.open({
          message: `Restaurant ${data.name} créé avec succès.`,
          type: 'is-success',
          position: 'is-bottom',
        });
        this.$emit('add-restaurant', data);
        this.$emit('close');
      } catch (err) {
        const { status } = err.response;

        switch (status) {
          case 409:
            this.$buefy.snackbar.open({
              indefinite: true,
              type: 'is-danger',
              message: 'Ce nom de restaurant est déjà utilisé, veuillez en choisir un autre.',
            });
            break;
          default:
            this.$buefy.snackbar.open({
              indefinite: true,
              type: 'is-danger',
              message: 'Impossible de créer le restaurant.',
            });
        }
      }
    },
    // eslint-disable-next-line func-names
    getAsyncData: debounce(async function (value) {
      this.resetAdress();

      const numberPattern = /\d+/g;
      const nbrs = value && value.match(numberPattern);
      const streetWithoutDigits = value && value.replace(/[0-9]/g, '');

      this.form.contactInfos.address.label = '';
      if (nbrs && nbrs[0]) {
        const houseNumber = nbrs[0];
        this.form.contactInfos.address.houseNumber = houseNumber;
      }
      if (streetWithoutDigits) {
        this.form.contactInfos.address.street = streetWithoutDigits.trim();
      }
      if (!value.trim().length) {
        this.results = [];
        return;
      }
      this.loading = true;
      try {
        const res = await fetch(this.setQuery(value));
        const data = await res.json();

        this.results = [];
        data.features.forEach((item) => this.results.push(item));
      } catch (err) {
        this.results = [];
        throw err;
      } finally {
        this.loading = false;
      }
    }, 500),
    onSelect(value) {
      this.form.contactInfos.address = {
        label: value?.properties?.label,
        city: value?.properties?.city,
        postCode: value?.properties?.postcode,
        cityCode: value?.properties?.citycode,
        houseNumber: value?.properties?.housenumber,
        street: value?.properties?.street || value?.properties?.name,
        location: {
          type: 'Point',
          coordinates: [
            value?.geometry?.coordinates[0],
            value?.geometry?.coordinates[1],
          ],
        },
      };
    },
    resetAdress() {
      this.form = {
        ...this.form,
        contactInfos: {
          ...this.form.contactInfos,
          address: {
            ...this.form.contactInfos.address,
            label: '',
            city: '',
            postCode: '',
            cityCode: '',
            houseNumber: '',
            street: '',
            location: {
              type: 'Point',
              coordinates: [],
            },
          },
        },
      };
    },
    setQuery(value) {
      const requestURL = 'https://api-adresse.data.gouv.fr/search/?q=';

      // type housenumber return also street type sometimes
      // https://github.com/etalab/adresse.data.gouv.fr/issues/111
      return `${requestURL + value}&type=housenumber&autocomplete=1&limit=15`;
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .textarea:not([rows]) {
    max-height: 40em;
    min-height: 6em;
  }
</style>